import { useTranslation } from "react-i18next"
import ExperienceSection from "../ExperienceSection"
import TechnicalSkills from "./TechnicalSkills";
import CompayInformation from "./CompayInformation";
import Projects from "./Projects";
import Tools from "./Tools";
import RoleAndLocation from "./RoleAndLocation";
import { SectionId } from "../../enums/SectionId";
import GoBackPageStructure from "../layout/GoBackPageStructure";
import { H2 } from "../layout/Headings";

interface ProjectsData {
    title: string;
    description: string;
    image: string;
}

interface ExperienceExtendedProps {
    image: string;
    company: string;
    companyDescription: string;
    link: string;
    workingModality?: string;
    period: string;
    location: string;
    role: string;
    experienceTasks: string[];
    projects: ProjectsData[],
    managementTool?: string;
    respositoryHosting?: string;
    versionControlSystem?: string;
    toolImg?: string;
    ui?: string;
    programmingLanguages: string[],
    technicalSkills: string[]
}

const ExperienceExtended = ({ company, image, role, period, workingModality, link, projects, companyDescription, location, experienceTasks, managementTool, respositoryHosting, versionControlSystem, toolImg, programmingLanguages, technicalSkills, ui }: ExperienceExtendedProps) => {

    const { t } = useTranslation();

    return <GoBackPageStructure
                goBackTo={SectionId.PROFESSIONAL_EXPERIENCE}>

                <div className="font-normal text-base italic my-4 text-gray-600 dark:text-gray-400">
                    {t(period)}
                </div>

                <div className=" mt-2">

                    <RoleAndLocation
                        location={location}
                        workingModality={workingModality}
                        role={role}
                        programmingLanguages={programmingLanguages}
                    />

                    <div className="mb-4 mt-8">
                        <CompayInformation
                            company={company}
                            image={image}
                            link={link}
                            companyDescription={companyDescription} />
                    </div>

                    {technicalSkills.length > 0 && <div className="my-4">
                        <TechnicalSkills technicalSkills={technicalSkills} />
                    </div>}

                    <div className="border  border-gray-200 dark:border-gray-600 rounded-lg text-left p-4 mt-4">
                    <H2><>{t('Experience')}</></H2>
                        <div className="col-span-2 mt-2">
                            <ExperienceSection experienceTasks={experienceTasks} />
                        </div>
                    </div>

                    {(managementTool || respositoryHosting || versionControlSystem) &&
                        <div className=" mt-4">
                            <Tools managementTool={managementTool}
                                respositoryHosting={respositoryHosting}
                                versionControlSystem={versionControlSystem}
                                toolImg={toolImg}
                                ui={ui}
                            />
                        </div>}

                    { projects.length > 0 && <div className="mt-4">
                        <Projects projects={projects} />
                    </div>
                    }
                </div>

    </GoBackPageStructure>
}
export default ExperienceExtended;